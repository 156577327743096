import { useState } from 'react';
import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInLeft, fadeInRight, fadeInUp } from '../../animations/variants';
import loaf from '../../assets/images/bread-calculator.png';
import smallLoaf from '../../assets/images/bread-calculator-small-loaf-size.png';
import { validateInput } from '../../helpers/bike-calculations';
import useContentAnimation from '../../hooks/use-content-animation';
import useNurseryCalculations from '../../hooks/use-nursery-calculations';
import { ScCalculatorAccordionWrapper } from '../../sections/EmployeeCycling/styled';
import formatNumberWithCommasAndCutDecimals from '../../utils/format/format-nuber-with-commas';
import { CalculatorAccordion } from '../CalculatorAccordion/CalculatorAccordion';
import { CalculatorSwitcher } from '../CalculatorSwitcher';
import { ScContainer } from '../container/styled';
import { Input } from '../Input';

import {
	ScCalculatorBlueberry,
	ScCalculatorBlueberryContent,
	ScCalculatorBrown,
	ScCalculatorCost,
	ScCalculatorInputContainer,
	ScCalculatorInputs,
	ScCalculatorInputWrapper,
	ScCalculatorLabelWrapper,
	ScCalculatorLeft,
	ScCalculatorNurseryInputsRow,
	ScCalculatorText,
	ScCalculatorTitle,
	ScCalculatorWrapper,
} from './styled';

interface INurseryCalculator {
	isItEmployee: boolean;
}

export const NurseryCalculator = ({ isItEmployee }: INurseryCalculator) => {
	const [isToggled, setIsToggled] = useState(false);
	const [voucherValue, setVoucherValue] = useState('');
	const [salary, setSalary] = useState('');
	const theme = useTheme();
	const savings = useNurseryCalculations(Number(voucherValue), Number(salary));
	const { ref, controls } = useContentAnimation();
	const { ref: accordionRef, controls: accordionControls } = useContentAnimation();

	const accordionsEmployee = [
		{
			title: 'Wait, how does that work?',
			columns: 2,
			text: [
				`<p>Right now, you probably pay for your nursery bill out of your take home pay, or net salary, after you’ve paid tax and national insurance. This means that, if your gross salary is <b>£${formatNumberWithCommasAndCutDecimals(
					salary,
				)}</b> per year, and your nursery fees are <b>£${formatNumberWithCommasAndCutDecimals(
					voucherValue,
				)}</b> per month, your take home pay is roughly <b>£${formatNumberWithCommasAndCutDecimals(
					String(isNaN(savings.withoutGogetaPayAfterNurseryBill) ? 0 : savings.withoutGogetaPayAfterNurseryBill)
				)}</b> after you've paid your nursery bill.</p><p>With Gogeta, your nursery still charges the same amount and your pay stays the same. The difference is you’re paying your nursery bill directly out of your gross salary, before you get taxed.</p>`,
				`<p>That means your take home pay (after your nursery bill has been paid) is <b>£${formatNumberWithCommasAndCutDecimals(
					String(isNaN(savings.withGogetaPayAfterNurseryBill) ? 0 : savings.withGogetaPayAfterNurseryBill),
				)}</b>, saving you <b>£${formatNumberWithCommasAndCutDecimals(
					String(isNaN(savings.monthlyTotalSavings) ? 0 : savings.monthlyTotalSavings),
				)}</b> every single month.</p><p>To run the workplace nursery scheme, Gogeta Nursery charges a platform fee, which is added to the amount you salary sacrifice every month. When you apply for Gogeta Nursery, you’ll get a full breakdown of the savings, the platform fee and the amount you’ll salary sacrifice every month. <b>All the savings shown in our calculators are inclusive of this fee, so the savings shown are the real savings you will experience.</b></p>`,
			],
		},
		{
			title: 'What about my nursery?',
			columns: 2,
			text: [
				`<p>Your nursery also benefits when you sign up to Gogeta. As part of the scheme, your employer makes an additional financial contribution to your child’s nursery.</p>`,
				`<p>This is 12.5% of your nursery fees and is paid directly to your nursery. It doesn’t cost you anything and for employers it’s covered by the national insurance contribution savings they make, so it’s cost neutral to them too.</p>`,
			],
		},
		{
			title: 'What is the workplace nursery scheme?',
			columns: 1,
			text: [
				`<p>Workplace nursery is an HMRC scheme that enables employees to pay for nursery fees out of their gross salary, saving on tax and national insurance.</p>
         <p>Your employer can offer this benefit to you by entering into a partnership with any OFSTED nursery you choose as long as certain conditions are met. Gogeta facilitates those partnerships and helps employers meet HMRC’s compliance standards.</p>
        `,
			],
		},
	];

	const accordionsEmployer = [
		{
			title: 'Wait, how does that work?',
			columns: 2,
			text: [
				`<p>Right now, your employee probably pays for their nursery bill out of their take home pay, or net salary, after they’ve paid tax and national insurance. This means that, if their gross salary is <b>£${formatNumberWithCommasAndCutDecimals(
					salary,
				)}</b> per year, and your nursery fees are <b>£${formatNumberWithCommasAndCutDecimals(
					voucherValue,
				)}</b> per month, your take home pay is roughly <b>£${formatNumberWithCommasAndCutDecimals(
					String(isNaN(savings.withoutGogetaPayAfterNurseryBill) ? 0 : savings.withoutGogetaPayAfterNurseryBill),
				)}</b> after you've paid your nursery bill.</p><p>With Gogeta, your nursery still charges the same amount and your pay stays the same. The difference is you’re paying your nursery bill directly out of your gross salary, before you get taxed.</p>`,
				`<p>That means your take home pay (after your nursery bill has been paid) is <b>£${formatNumberWithCommasAndCutDecimals(
					String(isNaN(savings.withGogetaPayAfterNurseryBill) ? 0 : savings.withGogetaPayAfterNurseryBill),
				)}</b>, saving you <b>£${formatNumberWithCommasAndCutDecimals(
					String(isNaN(savings.monthlyTotalSavings) ? 0 : savings.monthlyTotalSavings),
				)}</b> every single month.</p><p>To run the workplace nursery scheme, Gogeta Nursery charges a platform fee, which is added to the amount you salary sacrifice every month. When you apply for Gogeta Nursery, you’ll get a full breakdown of the savings, the platform fee and the amount you’ll salary sacrifice every month. <b>All the savings shown in our calculators are inclusive of this fee, so the savings shown are the real savings you will experience.</b></p>`,
			],
		},
		{
			title: 'What is the workplace nursery scheme?',
			columns: 2,
			text: [
				`<p>Workplace nursery is an HMRC scheme that enables employees to pay for nursery fees out of their gross salary, saving on tax and national insurance. Rather than building a workplace nursery on site, employers are allowed to form partnerships with private nurseries as long as certain conditions are met.</p>`,
				`<p>Gogeta facilitates those partnerships with employers and nurseries to meet HMRC’s compliance standards. At the same time, we make it as easy as possible for you to offer this benefit to staff and it's totally cost neutral to you.</p>`,
			],
		},
		{
			title: 'How do I sign up? ',
			columns: 1,
			text: [`<p>Click the sign up button at the top of this page to get going.</p>`],
		},
	];

	const accordions = isItEmployee ? accordionsEmployee : accordionsEmployer;

	return (
		<motion.section initial="hidden" animate={controls} variants={animationContainer}>
			<ScContainer>
				<ScCalculatorWrapper>
					<ScCalculatorLeft>
						<ScCalculatorInputs variants={fadeInRight}>
							<ScCalculatorInputWrapper>
								<ScCalculatorLabelWrapper>
									<ScCalculatorTitle>Take your dough</ScCalculatorTitle>
								</ScCalculatorLabelWrapper>
								<ScCalculatorInputContainer>
									<Input
										value={salary}
										changeHandler={(str) => {
											if (validateInput(str)) {
												setSalary(str);
											}
										}}
										background={theme.colors.white}
										placeholder="Enter annual salary"
									/>
								</ScCalculatorInputContainer>
							</ScCalculatorInputWrapper>
							<ScCalculatorInputWrapper>
								<ScCalculatorLabelWrapper>
									<ScCalculatorTitle>And deduct your nursery fees</ScCalculatorTitle>
								</ScCalculatorLabelWrapper>
								<Input
									value={voucherValue}
									changeHandler={(str) => {
										if (validateInput(str)) {
											setVoucherValue(str);
										}
									}}
									background={theme.colors.white}
									placeholder="Enter monthly nursery bill"
								/>
							</ScCalculatorInputWrapper>
						</ScCalculatorInputs>
						<ScCalculatorBrown ref={ref} variants={fadeInRight}>
							{isToggled ? (
								<img src={smallLoaf as string} alt="bread" />
							) : (
								<img src={loaf as string} alt="small-loaf" />
							)}
						</ScCalculatorBrown>
					</ScCalculatorLeft>
					<ScCalculatorBlueberry isActive={!isToggled} variants={fadeInLeft}>
						<ScCalculatorBlueberryContent>
							<ScCalculatorCost>
								<ScCalculatorTitle isLarge>
									Paying your monthly nursery bill <i>{isToggled ? 'after' : 'before'}</i> you get
									taxed saves you:
								</ScCalculatorTitle>
								<ScCalculatorNurseryInputsRow>
									<ScCalculatorCost>
										<Input
											isLargeText
											isRounded
											background={isToggled ? theme.colors.darkText : theme.colors.strongWhite}
											color={isToggled ? theme.colors.strongWhite : theme.colors.darkText}
											value={
												salary === ''
													? 0
													: isToggled
													? 0
													: isNaN(savings.monthlyTotalSavings)
													? 0
													: Math.trunc(savings.monthlyTotalSavings)
											}
										/>
										<ScCalculatorText>per month</ScCalculatorText>
									</ScCalculatorCost>
									<ScCalculatorText> </ScCalculatorText>
									<ScCalculatorCost>
										<Input
											isLargeText
											isRounded
											background={isToggled ? theme.colors.darkText : theme.colors.strongWhite}
											color={isToggled ? theme.colors.strongWhite : theme.colors.darkText}
											value={
												salary === ''
													? 0
													: isToggled
													? 0
													: isNaN(savings.totalSavings)
													? 0
													: Math.trunc(savings.totalSavings)
											}
										/>
										<ScCalculatorText>per year</ScCalculatorText>
									</ScCalculatorCost>
								</ScCalculatorNurseryInputsRow>
							</ScCalculatorCost>
							<ScCalculatorText>
								{isToggled ? (
									<>
										Leaving you with{' '}
										<b>
											£
											{formatNumberWithCommasAndCutDecimals(
												savings.withoutGogetaPayAfterNurseryBill.toString(),
											)}
										</b>{' '}
										monthly after you've paid tax on your pay packet, and deducted your nursery
										fees.
									</>
								) : (
									<>
										Leaving you a pay packet of{' '}
										<b>
											£
											{formatNumberWithCommasAndCutDecimals(
												savings.withGogetaPayAfterNurseryBill
													? savings.withGogetaPayAfterNurseryBill.toString()
													: '0',
											)}
										</b>{' '}
										monthly after you've paid your nursery bill and tax
									</>
								)}
							</ScCalculatorText>
							<CalculatorSwitcher isToggled={isToggled} setIsToggled={setIsToggled} />
						</ScCalculatorBlueberryContent>
					</ScCalculatorBlueberry>
				</ScCalculatorWrapper>
				<motion.section initial="hidden" animate={accordionControls} variants={animationContainer}>
					<ScCalculatorAccordionWrapper>
						{accordions.map((item) => (
							<MotionDiv key={item.title} variants={fadeInUp}>
								<CalculatorAccordion
									title={item.title}
									text={item.text}
									columns={item.columns}
									key={item.title}
								/>
							</MotionDiv>
						))}
					</ScCalculatorAccordionWrapper>
					<div ref={accordionRef} />
				</motion.section>
			</ScContainer>
		</motion.section>
	);
};
