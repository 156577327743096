import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

import { useLocation } from '@reach/router';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInUp } from '../../animations/variants';
import { ReactComponent as Bot } from '../../assets/icons/bot-wave.svg';
import { ReactComponent as Top } from '../../assets/icons/top-wave.svg';
import { NurseryCalculator } from '../../components/Calculators/nursery-calculator';
import { ScContainer } from '../../components/container/styled';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { scrollToRef } from '../../utils/scroll';
import {
	ScBikesCalculatorSection,
	ScEmployeeCyclingCalculatorContainer,
	ScEmployeeCyclingCalculatorWrapper,
} from '../EmployeeCycling/styled';

export const NurseryCalculatorSection = ({
	title,
	isItEmployee,
}: {
	title?: string;
	isItEmployee: boolean;
}) => {
	const { ref, controls } = useContentAnimation();
	const calculator = useRef<HTMLInputElement | null>(null);
	const location = useLocation();

	useEffect(() => {
		if (location.hash === '#savings-calculator') {
			scrollToRef(calculator);
		}
	}, [location]);

	return (
		<motion.section initial="hidden" animate={controls} variants={animationContainer}>
			<ScBikesCalculatorSection
				ref={ref}
				initial="hidden"
				animate={controls}
				variants={animationContainer}
			>
				<ScEmployeeCyclingCalculatorContainer>
					<Top />
					<ScEmployeeCyclingCalculatorWrapper>
						<ScContainer ref={calculator}>
							<MotionDiv variants={fadeInUp}>
								<ScTitle ref={ref}>{title || 'See your savings'}</ScTitle>
							</MotionDiv>
							<NurseryCalculator isItEmployee={isItEmployee} />
						</ScContainer>
					</ScEmployeeCyclingCalculatorWrapper>
					<Bot />
				</ScEmployeeCyclingCalculatorContainer>
			</ScBikesCalculatorSection>
		</motion.section>
	);
};
