import { useMemo } from "react";
import {
  ICalculatorData,
  nurseryCalculate,
} from "../helpers/nursery-calculations";

const useNurseryCalculations = (
  voucher: number,
  salary: number
): ICalculatorData => {
  const calculatorValues = useMemo(() => {
    return nurseryCalculate(voucher, salary);
  }, [voucher, salary]);

  return calculatorValues;
};

export default useNurseryCalculations;
