export interface ICalculatorData {
	platformFeePercentage: number;
	totalSalSac: number;
	monthlyTakeHomePay: number;
	taxFreePrice: number;
	totalSavings: number;
	monthlyTotalSavings: number;
	totalSavingsPercentage: number;
	netFee: number;
	monthlyChildcareBill: number;
	voucherValue: number;
	salary: number;
	withoutGogetaPayAfterNurseryBill: number;
	withGogetaPayAfterNurseryBill: number;
}

function calc_taxes(salary: number) {
	let tax = 0;
	let threshold = 0;
	let incomeTaxRates = 0;
	if (salary <= 12570) {
		tax = 0;
		threshold = 0;
		incomeTaxRates = (salary - 0) / 12;
	} else if (salary >= 12571 && salary <= 50270) {
		tax = 28.0;
		threshold = 12571;
		incomeTaxRates = (salary - (salary - 12571) * 0.28) / 12;
	} else if (salary >= 50271 && salary <= 125140) {
		tax = 42.0;
		threshold = 50271;
		incomeTaxRates = (salary - (37700 * 0.28 + (salary - 50270) * 0.42)) / 12;
	} else {
		tax = 47.0;
		threshold = 125141;
		incomeTaxRates = (salary - (37700 * 0.28 + 74730 * 0.42 + (salary - 125000) * 0.47)) / 12;
	}

	return { data: [(100 - tax) / 100, threshold], tax: tax, incomeTaxRates };
}

export const nurseryCalculate = (voucherValue: number, salary: number): ICalculatorData => {
	const monthlyFees = voucherValue ? Number(voucherValue) : 0;
	const annualSalary = salary ? Number(salary) : 0;
	const annual = monthlyFees * 12;
	const platformFee = annual * 0.125;
	const value = calc_taxes(annualSalary)?.data;
	const tax1 = value[0];
	const threshold = value[1];
	const value2 = calc_taxes(annualSalary - annual)?.data;
	const tax2 = value2[0];
	const incomeTaxRates = calc_taxes(salary).incomeTaxRates;
	const differenceOverThreshold = threshold;
	const totalSalarySacrifice = annual + platformFee;
	const taxRate = calc_taxes(annualSalary)?.tax / 100;
	const netFeePercentage = (platformFee * (1 - taxRate)) / annual;
	const regularGross = totalSalarySacrifice / 12;
	const netCost =
		(annualSalary - differenceOverThreshold) * tax1 +
		(annual - (annualSalary - differenceOverThreshold)) * tax2;
	const savings = annual - netCost;
	const savingsPercentage = (savings / annual) * 100;
	const netRegular = Number((regularGross * (1 - savingsPercentage / 100)).toFixed(2));
	const monthlyTakeHome = regularGross * (1 - savingsPercentage / 100);
	const taxFreePrice = netRegular;
	const effectiveAnnualCost = taxFreePrice * 12;
	const totalSavings = annual - effectiveAnnualCost;
	const totalSavingsPercentage = Math.round((totalSavings / annual) * 100);
	const withoutGogetaPayAfterNurseryBill = Number((incomeTaxRates - voucherValue).toFixed(2));

	const withGogetaPayAfterNurseryBill = Number((incomeTaxRates - monthlyTakeHome).toFixed(2));

	return {
		platformFeePercentage: platformFee,
		totalSalSac: Number((totalSalarySacrifice / 12).toFixed(2)),
		monthlyTakeHomePay: Number(monthlyTakeHome.toFixed(2)),
		taxFreePrice: netRegular,
		totalSavings: Number(totalSavings.toFixed(2)),
		monthlyTotalSavings: Number((totalSavings / 12).toFixed(2)),
		totalSavingsPercentage: totalSavingsPercentage,
		netFee: Number(netFeePercentage.toFixed(2)),
		monthlyChildcareBill: netRegular,
		voucherValue: monthlyFees,
		salary: annualSalary,
		withoutGogetaPayAfterNurseryBill,
		withGogetaPayAfterNurseryBill,
	};
};
